export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorPhoneNumber = phNumber => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[- +()]*[0-9][- +()0-9]*$/
  /* eslint-enable no-useless-escape */
  const validNumber = regExp.test(phNumber)
  return validNumber
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrl = val => {
  if (!val || val.length === 0) {
    return true
  }
  const re = /^(https?:\/\/)?(www\.)?(?!.*\.\.)(?!.*--)[a-zA-Z0-9]([a-zA-Z0-9.-]*[a-zA-Z0-9])?(\.[a-zA-Z]{2,10}){1,5}(\/[a-zA-Z0-9-._~!$&?'()*+,;=:@%]*)*\/?$/
  const isValidUrl = re.test(val)

  if (isValidUrl) {
    const domain = val.match(/^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/)

    if (domain) {
      const domainParts = domain[1].split('.')
      return new Set(domainParts).size === domainParts.length
    } else return false
  } else return false
}
