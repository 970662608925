import { kpEndpoint, kpRequest, kpParams } from '@/axios'

export const GET_LEADAI_LIST = 'getLeadAiList'
export const FETCH_TAILORED_LEADS = 'fetchTailoredLeads'
export const FETCH_LEAD_DETAIL = 'fetchLeadDetail'
export const FETCH_LEAD_FILTERS = 'fetchLeadFilters'
export const UPDATE_APPLIED_FILTERS = 'updateFilterState'
export const UPDATE_SEARCH_TEXT = 'updateSearchText'
export const UPDATE_CURRENT_PAGE = 'updateCurrentPage'
export const UPDATE_SELECTED_COUNTRY = 'updateSelectedCountry'
export const UPDATE_SELECTED_STORE_TYPES = 'updateSelectedStoreTypes'
export const UPDATE_SELECTED_CATEGORIES = 'updateSelectedCategories'
export const UPDATE_SELECTED_RETAIL_PRICES = 'updateSelectedRetailPrices'
export const RESET_FILTERS = 'resetFilters'
export const GET_VIEWED_LEADS = 'getViewedLeads'
export const GET_ONE_LEAD = 'getOneLead'
export const SET_TAILORED_LEADS_FORM_DATA = 'setTailoredLeadsFormData'

const state = {
  searchText: '',
  selectedCountry: [],
  selectedStoreTypes: [],
  selectedCategories: [],
  selectedRetailPrices: [],
  appliedFilters: {},
  tailoredLeadsFormData: null,
  currentPage: 1,
}

const actions = {
  async [GET_LEADAI_LIST](ctx, payload) {
    return await kpRequest({
      ...kpEndpoint.leadAI.getList,
      payload: {
        pageNumber: ctx.state.currentPage,
        pageSize: payload.pageSize,
        search: ctx.state.searchText,
        filters: ctx.state.appliedFilters,
      },
    })
  },
  async [FETCH_TAILORED_LEADS](ctx, payload) {
    return await kpRequest({
      ...kpEndpoint.leadAI.fetchTailoredLeads,
      payload,
    })
  },
  async [FETCH_LEAD_DETAIL](ctx, id) {
    return await kpRequest({ ...kpParams(kpEndpoint.leadAI.getDetail, id) })
  },

  async [FETCH_LEAD_FILTERS](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.leadAI.getFilters, payload })
  },
  async [GET_VIEWED_LEADS](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.leadAI.getViewedLeads, payload })
  },
  async [GET_ONE_LEAD](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.leadAI.getOneLead, payload })
  },
}

const mutations = {
  [UPDATE_APPLIED_FILTERS](state, filters = {}) {
    state.appliedFilters = filters
  },
  [UPDATE_SEARCH_TEXT](state, searchText = '') {
    state.searchText = searchText
  },
  [UPDATE_CURRENT_PAGE](state, page = 1) {
    state.currentPage = page
  },
  [UPDATE_SELECTED_COUNTRY](state, countries = []) {
    state.selectedCountry = countries
  },
  [UPDATE_SELECTED_STORE_TYPES](state, storeTypes = []) {
    state.selectedStoreTypes = storeTypes
  },
  [UPDATE_SELECTED_CATEGORIES](state, categories = []) {
    state.selectedCategories = categories
  },
  [UPDATE_SELECTED_RETAIL_PRICES](state, prices = []) {
    state.selectedRetailPrices = prices
  },
  [SET_TAILORED_LEADS_FORM_DATA](state, data = {}) {
    state.tailoredLeadsFormData = {
      ...data,
      ...state.tailoredLeadsFormData,
    }
  },
  [RESET_FILTERS](state) {
    state.searchText = ''
    state.selectedCountry = []
    state.selectedStoreTypes = []
    state.selectedCategories = []
    state.selectedRetailPrices = []
    state.appliedFilters = {}
    state.currentPage = 1
  },
}

export default {
  state,
  actions,
  mutations,
}
